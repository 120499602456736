import "./contents.css"

import voxsculptImg from "./assets/voxsculpt.gif";
import jellyfaceImg from "./assets/jellyface.gif";
import boopImg from "./assets/boop.gif";
import quiconvertImg from "./assets/quiconvert.gif"
import portalslingshotImg from "./assets/portalslingshot.gif"
import mechaTriggerImg from "./assets/mecha_trigger.gif"
import voxDrawImg from "./assets/vision_vox_draw.gif"
import postNoPixelsImg from "./assets/post_no_pixels.gif"
import exclamationImg from "./assets/exclamation.gif"
import cubenadoImg from "./assets/cubenado.gif"
import helloImg from "./assets/hello.gif"
import leapPongImg from "./assets/leap_pong.gif"
import jellyFilterImg from "./assets/jelly_filter.gif"
import nuggetFilterImg from "./assets/nugget_filter.gif"
import inUrEyeImg from "./assets/in_ur_eye.gif"
import corBdayFilterImg from "./assets/cor_bday_filter.gif"
import smudgeImg from "./assets/smudge.gif"
import squigglyImg from "./assets/squiggly.gif"
import wavyImg from "./assets/wavy.gif"

import fileThumb from "./assets/file_thumb.gif"
import voxsculptThumb from "./assets/voxsculpt_thumb.gif";
import jellyfaceThumb from "./assets/jellyface_thumb.gif";
import boopThumb from "./assets/boop_thumb.gif";
import quiconvertThumb from "./assets/quiconvert_thumb.gif";
import portalslingshotThumb from "./assets/portalslingshot_thumb.gif";
import mechaTriggerThumb from "./assets/mecha_trigger_thumb.gif";
import voxDrawThumb from "./assets/vision_vox_draw_thumb.gif"
import postNoPixelsThumb from "./assets/post_no_pixels_thumb.png"
import exclamationThumb from "./assets/exclamation_thumb.gif";
import cubenadoThumb from "./assets/cubenado_thumb.gif"
import helloThumb from "./assets/hello_thumb.gif"
import leapPongThumb from "./assets/leap_pong_thumb.gif"
import jellyFilterThumb from "./assets/jelly_filter_thumb.gif"
import nuggetFilterThumb from "./assets/nugget_filter_thumb.gif"
import inUrEyeThumb from "./assets/in_ur_eye_thumb.gif"
import corBdayFilterThumb from "./assets/cor_bday_filter_thumb.gif"
import smudgeThumb from "./assets/smudge_thumb.gif"
import squigglyThumb from "./assets/squiggly_thumb.gif"
import wavyThumb from "./assets/wavy_thumb.gif"

import exlcamationROM from "./assets/ggj.nes";


export const shortcuts = [
    {
        id: "about",
        thumbnail: helloThumb,
        pos: [30, 36]
    },
    {
        id: "voxsculpt",
        thumbnail: voxsculptThumb,
        pos: [100, 120],
    },
    {
        id: "jellyface",
        thumbnail: jellyfaceThumb,
        pos: [230, 230],
    },
    {
        id: "boop",
        thumbnail: boopThumb,
        pos: [200, 80],
    },
    {
        id: "quiconvert",
        thumbnail: quiconvertThumb,
        pos: [35, 500]
    },
    {
        id: "portalslingshot",
        thumbnail: portalslingshotThumb,
        pos: [50, 200]
    },
    {
        id: "mecha_trigger",
        thumbnail: mechaTriggerThumb,
        pos: [280, 155]
    },
    {
        id: "vision_vox_draw",
        thumbnail: voxDrawThumb,
        pos: [300, 65]
    },
    {
        id: "post_no_pixels",
        thumbnail: postNoPixelsThumb,
        pos: [50, 390]
    },
    {
        id: "exclamation",
        thumbnail: exclamationThumb,
        pos: [150, 290]
    },
    {
        id: "cubenado",
        thumbnail: cubenadoThumb,
        pos: [383, 455]
    },
    {
        id: "leap_pong",
        thumbnail: leapPongThumb,
        pos: [268, 552]
    },
    {
        id: "filter_jelly",
        thumbnail: jellyFilterThumb,
        pos: [160, 415]
    },
    {
        id: "filter_nugget",
        thumbnail: nuggetFilterThumb,
        pos: [290, 355]
    },
    {
        id: "filter_in_ur_eye",
        thumbnail: inUrEyeThumb,
        pos: [367, 278]
    },
    {
        id: "filter_cor_bday",
        thumbnail: corBdayFilterThumb,
        pos: [27, 608]
    },
    {
        id: "filter_squiggly",
        thumbnail: squigglyThumb,
        pos: [127, 508]
    },
    {
        id: "filter_smudge",
        thumbnail: smudgeThumb,
        pos: [117, 688]
    },
    {
        id: "filter_wavy",
        thumbnail: wavyThumb,
        pos: [292, 702]
    }
];

export const allItems = {
    "about": {
        title: "hello",
        contents:
            <div className="contents">
                <img className="contentImg" src={helloImg} alt="hello"></img>
                <div>
                    feel free to drag and re-arrange things as you like
                </div>
                <br />
                <div>
                    - josh
                </div>
                <hr />
                <div className="linkList">
                    <a href="https://github.com/joshshadik" target="_blank" rel="noreferrer">github ↗</a>
                    <a href="https://www.linkedin.com/in/josh-shadik/" target="_blank" rel="noreferrer">linkedin ↗</a>
                </div>
            </div>,
        type: "note",
    },
    "voxsculpt": {
        title: "voxsculpt",
        contents:
            <div className="contents">
                <img className="contentImg" src={voxsculptImg} alt="voxsculpt"></img>
                <div>
                    sculpt and paint a block of voxels into your own creation. choose from different colors, brush sizes, styles and more
                </div>
                <br />
                <div>
                    <a href="https://experiments.withgoogle.com/voxsculpt">featured on google experiments</a>
                </div>
                <br />
                <div>
                    <a href="https://jsh.sh/voxsculpt/src" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note",
    },

    "jellyface": {
        title: "jelly face",
        contents:
            <div className="contents">
                <img className="contentImg" src={jellyfaceImg} alt="jelly face"></img>
                <div>
                    a fun webgl toy app. stretch and jiggle faces
                </div>
                <br />
                <div>
                    <a href="https://jsh.sh/jelly-face/" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "boop": {
        title: "boop.",
        contents:
            <div className="contents">
                <img className="contentImg" src={boopImg} alt="boop game"></img>
                <div>
                    a minimal puzzle game for iOS
                </div>
                <br />
                <div>
                    <a href="https://apps.apple.com/fi/app/boop/id6479205625" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "quiconvert": {
        title: "QuiConvert",
        contents:
            <div className="contents">
                <img className="contentImg" src={quiconvertImg} alt="QuiConvert"></img>
                <div>
                    simple video editing operations done locally in the browser. using ffmpeg built for WASM
                </div>
                <br />
                <div>
                    <a href="https://jsh.sh/quiconvert/" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "portalslingshot": {
        title: "Portal Slingshot",
        contents:
            <div className="contents">
                <img className="contentImg" src={portalslingshotImg} alt="Portal Slingshot"></img>
                <div>
                    simple 2D portal-inspired game with 40 levels
                </div>
                <br />
                <div className="linkList">
                    <a href="https://play.google.com/store/apps/details?id=com.applebat.androidgames.platformPortals" target="_blank" rel="noreferrer">google play store ↗</a>
                    <a href="https://jsh.sh/portal-slingshot/" target="_blank" rel="noreferrer">try it out on web ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "mecha_trigger": {
        title: "Mecha Trigger",
        contents:
            <div className="contents">
                <img className="contentImg" src={mechaTriggerImg} alt="Mecha Trigger"></img>
                <div>
                    a mech fighter game, where you control the mech by typing commands into a terminal
                </div>
                <br />
                <div>
                    <a href="https://gamedev.msu.edu/mecha-trigger/" target="_blank" rel="noreferrer">see details ↗</a>
                </div>
                <hr />
                <div>
                    featured press
                    <br />
                    <div className="linkList">
                        <a href="https://www.rockpapershotgun.com/mecha-trigger-free-game" target="_blank" rel="noreferrer">rock paper shotgun ↗</a>
                        <a href="https://www.destructoid.com/mecha-trigger-made-me-feel-real-dumb-before-it-made-me-feel-kind-of-smart/" target="_blank" rel="noreferrer">destructoid ↗</a>
                    </div>
                </div>
            </div>,
        type: "note"
    },

    "vision_vox_draw": {
        title: "Apple Vision Voxel Drawing",
        contents:
            <div className="contents">
                <img className="contentImg" src={voxDrawImg} alt="Apple Vision Voxel Drawing"></img>
                <div>
                    a small Apple Vision Pro app to draw and animal little voxel scenes
                </div>
                <br />
                <div>
                    unreleased
                </div>
            </div>,
        type: "note"
    },

    "post_no_pixels": {
        title: "post no pixels",
        contents:
            <div className="contents">
                <img className="contentImg" src={postNoPixelsImg} alt="Post No Pixels"></img>
                <div>
                    a tezos (blockchain) drawing app
                </div>
                <br />
                <div>
                    <a href="https://postnopixels.com" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "exclamation": {
        title: "!",
        contents:
            <div className="contents">
                <img className="contentImg" src={exclamationImg} alt="!"></img>
                <div>
                    a 2 player battle game made for NES for the Global Game Jam 2015
                    <br />
                    try it out in an emulator to play
                </div>
                <br />
                <div>
                    <a href="https://v3.globalgamejam.org/2015/games-3" target="_blank" rel="noreferrer">see website ↗</a>
                </div>
                <br />
                <div>
                    <a href={exlcamationROM} target="_blank" rel="noreferrer">download ROM ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "cubenado": {
        title: "cubenado",
        contents:
            <div className="contents">
                <img className="contentImg" src={cubenadoImg} alt="cubenado"></img>
                <div>
                    a little webgl tornado of cubes
                </div>
                <br />
                <div>
                    <a href="https://jsh.sh/cubenado/" target="_blank" rel="noreferrer">try it out ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "leap_pong": {
        title: "leap pong",
        contents:
            <div className="contents">
                <img className="contentImg" src={leapPongImg} alt="leap pong"></img>
                <div>
                    a vr beer pong game made for a client in school
                </div>
                <br />
                <div>
                    <a href="https://www.youtube.com/watch?v=IMlz61drHMw&ab_channel=NickThurston" target="_blank" rel="noreferrer">full video ↗</a>
                </div>
            </div>,
        type: "note"
    },

    "filter_jelly": {
        title: "jelly face AR filter",
        contents:
            <div className="contents">
                <img className="contentImg" src={jellyFilterImg} alt="jelly filter"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"

    },

    "filter_nugget": {
        title: "nugget cam",
        contents:
            <div className="contents">
                <img className="contentImg" src={nuggetFilterImg} alt="nugget cam"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    },

    "filter_in_ur_eye": {
        title: "in ur eye",
        contents:
            <div className="contents">
                <img className="contentImg" src={inUrEyeImg} alt="in ur eye"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    },

    "filter_cor_bday": {
        title: "cor bday filter",
        contents:
            <div className="contents">
                <img className="contentImg" src={corBdayFilterImg} alt="cor bday filter"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    },

    "filter_squiggly": {
        title: "squiggly filter",
        contents:
            <div className="contents">
                <img className="contentImg" src={squigglyImg} alt="squiggly filter"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    },

    "filter_wavy": {
        title: "wavywavywavy filter",
        contents:
            <div className="contents">
                <img className="contentImg" src={wavyImg} alt="wavy filter"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    },

    "filter_smudge": {
        title: "smudge filter",
        contents:
            <div className="contents">
                <img className="contentImg" src={smudgeImg} alt="smudge filter"></img>
                <div>
                    an instagram filter made in SparkAR (now shutdown and unavailable)
                </div>
            </div>,
        type: "note"
    }
}

export default allItems;
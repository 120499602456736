import "./shortcut.css";
import clsx from "clsx";

import { Draggable } from "./draggable";
import { useState } from "react";

export function Shortcut(props) {
  const { thumbnail, onOpen, ...otherProps } = props;

  const [isSelected, setIsSelected] = useState(false)

  const handleClick = (e, pos) => {
    switch (e.detail) {
      case 1:
        // setIsSelected(true);
        onOpen(pos);
        break;
      case 2:
        // onOpen(pos);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  return (
    <Draggable className={clsx("shortcut", isSelected ? "highlighted" : null)} {...otherProps} onClick={handleClick}>
      <img src={thumbnail} width={64} height={64} alt="shortcut icon"></img>
    </Draggable>
  );
}

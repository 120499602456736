import { useEffect, useCallback, useState, useRef } from "react";
// import { RSVP } from "./rsvp";
import { useNavigate } from "react-router-dom";
import { CanvasContext } from "./canvas-context";
// import { Invitation } from "./invitation/invitation";
// import { PostWedding } from "./post-wedding/post-wedding";
// import { SaveTheDateContainer } from "./save-the-date-container";
// import { HonoluluRecs } from "./honolulu-recs";
import { StickyNote, NoteText } from "./sticky-note";
import { Shortcut } from "./shortcut";
import { vec3, mat4 } from "gl-matrix";

import clsx from "clsx";

// import img01 from "./assets/img/01.jpg";
// import img02 from "./assets/img/02.jpg";

// import { ReactComponent as TitleSVG } from "./assets/home/title.svg";
// import { ReactComponent as InvitationSVG } from "./assets/home/invitation.svg";
// import { ReactComponent as RSVPSVG } from "./assets/home/rsvp.svg";
// import { ReactComponent as HonoluluSVG } from "./assets/home/honolulu.svg";
// import { ReactComponent as SaveTheDateSVG } from "./assets/home/save-the-date.svg";
// import { ReactComponent as PostWeddingSVG } from "./assets/home/post-wedding.svg";
// import { ReactComponent as ItinerarySVG } from "./assets/home/itinerary.svg";
// import { ReactComponent as RegistrySVG } from "./assets/home/registry.svg";
// import { ReactComponent as RegistryCardSVG } from "./assets/home/registry-card.svg";

import { Draggable } from "./draggable";
import "./home.css";
// import { ComingSoon } from "./coming-soon";
import { createTransform } from "./transform";
import { OverlayCanvasController } from "./overlay-canvas/overlay-canvas-controller";
import fragmentShader from "./overlay-canvas/fragment.glsl";
import vertexShader from "./overlay-canvas/vertex.glsl";
import modelFragmentShader from "./overlay-canvas/modelFragment.glsl";
import modelVertexShader from "./overlay-canvas/modelVertex.glsl";
// import { Itinerary } from "./itinerary/itinerary";


import {allItems, shortcuts} from "./contents";

let lastTime = 0;
let currVelocity = [0, 0];
let currAnimID = null;
let scaleVelocity = 0.0;
let deltaTime = 0.01;
let posBuffer = [null, null, null, null, null, null, null, null, null, null, null, null];
let posBufferIndex = 0;
let posBufferCount = 0;
//let currPos = [0, 0];
let hasTargetPos = false;
let targetPos = [0, 0];
let maxPos = [1000, 1000];
let targetScale = 1.0;
let lastTouchDist = -1;

let lastPressPos = null;

let isDragging = false;
let isCurrZooming = false;

let isFirstLoad = true;

let nextItemID = 1;

const setHasTargetPos = (hasTarget) => {
  hasTargetPos = hasTarget;
};

const setTargetPos = (pos) => {
  targetPos = pos;
};

const setMaxPos = (pos) => {
  maxPos = pos;
};

const setTargetScale = (scale) => {
  targetScale = scale;
};

const setIsDragging = (drag) => {
  isDragging = drag;
};

const HomeButton = (props) => {
  const { pos, className, ...rest } = props;
  return <Draggable className={clsx(className, "homeButton")} noShadow pos={pos} {...rest} />;
};


  const initalWindow = {
    ...allItems["about"],
    initialPos: [64, 64],
    id: 0,
  };


export default function Home(props) {
  const { page } = props;

  const overlayCanvasRef = useRef(null);

  const [isZooming, setIsZooming] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const canvasRoot = useRef(null);
  const rootTransform = useRef(null);
  const modelTransform = useRef(null);

  const originalScale = useRef(null);
  const zoomKeyPressed = useRef(false);

  const [openedItems, setOpenedItems] = useState([initalWindow])
  const [orderedShortcuts, setOrderedShortcuts] = useState(shortcuts)

  const portraitMatches = window.matchMedia("(orientation: portrait)"); // !!window.screen && window.screen.height > window.screen.width;
  const isPortraitMode = portraitMatches?.matches;

  const onDragOverEvent = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!rootTransform.current) {
      rootTransform.current = createTransform({ onUpdate: onTransformUpdate });
      rootTransform.current.setPosition([targetPos[0], targetPos[1], 0]);
      rootTransform.current.setScale([targetScale, targetScale, targetScale]);
      window.rootTransform = rootTransform.current;
    }
  });

  const onTransformUpdate = () => {
    if (canvasRoot.current && rootTransform.current) {
      rootTransform.current.updateInverse();
      canvasRoot.current.style.transform = rootTransform.current.getTransformStr();
    }
  };

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const onKeyDown = (ev) => {
    const key = ev.key;
    if (key === "Meta") {
      zoomKeyPressed.current = true;
    }
  };

  const onKeyUp = (ev) => {
    const key = ev.key;
    if (key === "Meta") {
      zoomKeyPressed.current = false;
    }
  };

  useEffect(() => {
    const keyDownEv = document.addEventListener("keydown", onKeyDown);
    const keyUpEv = document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", keyDownEv);
      document.removeEventListener("keyup", keyUpEv);
    };
  }, []);

  const onScroll = (ev) => {
    ev.preventDefault();
    // if (zoomKeyPressed.current) {
      const px = ev.clientX;
      const py = ev.clientY;

      updateZoom(ev.deltaY * -0.001, px, py);
    // } else {
    //   const move = [-ev.deltaX, -ev.deltaY, 0];
    //   rootTransform.current.scaleFromOrigin(0.0, move);
    //   setHasTargetPos(false);
    // }
  };

  const updateDrag = (pos) => {
    if (pos[0] <= 0 || pos[1] <= 0) return;

    if (lastPressPos) {
      let move = [pos[0] - lastPressPos[0], pos[1] - lastPressPos[1], 0.0];
      rootTransform.current.scaleFromOrigin(0.0, move);
      setHasTargetPos(false);
    }

    lastPressPos = [pos[0], pos[1]];
  };

  function updateScale(newScale, px, py) {
    const prevScale = rootTransform.current.scale[0];

    // Restrict scale
    const canvasScale = Math.min(Math.max(0.25, newScale), 8);

    const scaleChange = canvasScale / prevScale;
    const scaleMult = (canvasScale - prevScale) / prevScale;
    const aspect = window.innerWidth / window.innerHeight;
    let offsetPos = [-px * scaleMult, -py * scaleMult, 0.0];

    if (scaleChange !== 1.0) {
      setHasTargetPos(false);
      setTargetScale(canvasScale);
      currVelocity = [0.0, 0.0];
      //rootTransform.current.translate([0, 0, 1.0 - scaleChange]);

      rootTransform.current.scaleFromOrigin(scaleChange, offsetPos);
    }
  }

  const updateZoom = (delta, px, py) => {
    updateScale(rootTransform.current.scale[0] + delta, px, py);
  };

  const finishDrag = () => {
    // if (!scrolledOrDraggedThisFrame) {
    if (posBufferCount >= posBuffer.length) {
      let end = posBuffer[posBufferIndex];
      let startIndex = (posBufferIndex + 1) % posBuffer.length;
      let start = posBuffer[startIndex];

      let offset = [end[0] - start[0], end[1] - start[1]];
      let totalTime = deltaTime * posBuffer.length;
      let velocity = [offset[0] / totalTime, offset[1] / totalTime];
      currVelocity = velocity;
    }

    for (let i = 0; i < posBuffer.length; i++) {
      posBuffer[i] = null;
    }
    posBufferIndex = 0;
    posBufferCount = 0;
    // }
  };

  const onDragStart = (e) => {
    setIsDragging(true);
    lastPressPos = [e.clientX, e.clientY];
  };

  const onDrag = (e) => {
    e.preventDefault();
    updateDrag([e.clientX, e.clientY]);
  };

  const onDragEnd = (e) => {
    // onDrag(e);
    setIsDragging(false);
    finishDrag();
  };

  const onDocumentTouchStart = (e) => {
    const touches = e.touches;
    if (touches.length === 2) {
      let avgX = (touches[0].clientX + touches[1].clientX) * 0.5;
      let avgY = (touches[0].clientY + touches[1].clientY) * 0.5;
      lastPressPos = [avgX, avgY];
    }
    lastTouchDist = -1;

    isCurrZooming = e.touches.length === 2;
    setIsZooming(isCurrZooming);
  };

  const onDocumentTouchMove = (e) => {
    if (e.touches.length > 1)
      e.preventDefault();

    const touches = e.touches;
    let avgX = 0;
    let avgY = 0;

    if (touches.length === 2) {
      let distX = touches[1].clientX - touches[0].clientX;
      let distY = touches[1].clientY - touches[0].clientY;
      avgX = (touches[0].clientX + touches[1].clientX) * 0.5;
      avgY = (touches[0].clientY + touches[1].clientY) * 0.5;

      let touchDist = Math.sqrt(distX * distX + distY * distY);
      let zoomDelta = 0;
      if (lastTouchDist >= 0) {
        zoomDelta = touchDist - lastTouchDist;
      }

      lastTouchDist = touchDist;

      if (zoomDelta !== 0) {
        updateZoom(zoomDelta * 0.003, avgX, avgY);
      }
      updateDrag([avgX, avgY]);
      isCurrZooming = true;
    } else {
      isCurrZooming = false;
    }
    setIsZooming(isCurrZooming);
  };

  const onDocumentTouchEnd = (e) => {
    isCurrZooming = e.touches.length === 2;
    setIsZooming(isCurrZooming);
  };

  const onGestureEvent = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    const startEv = document.addEventListener("touchstart", onDocumentTouchStart, false);
    const moveEv = document.addEventListener("touchmove", onDocumentTouchMove, false);
    const endEv = document.addEventListener("touchend", onDocumentTouchEnd, false);
    const wheelEv = document.addEventListener("wheel", onScroll, { passive: false });
    return () => {
      document.removeEventListener("touchstart", startEv);
      document.removeEventListener("touchmove", moveEv);
      document.removeEventListener("touchend", endEv);
      document.removeEventListener("wheel", wheelEv);
    };
  }, []);

  const onTouchStart = (e) => {
    e.preventDefault();
    const touch = e.targetTouches[0];
    const touches = e.touches;

    if (touches.length === 1) {
      setIsDragging(true);
      lastPressPos = [touch.clientX, touch.clientY];
    }
  };

  const onTouchMove = (e) => {
    e.preventDefault();

    const touches = e.touches;
    if (isDragging && touches.length === 1) {
      const touch = e.targetTouches[0];
      updateDrag([touch.clientX, touch.clientY]);
    } else if (isDragging) {
      setIsDragging(false);
      finishDrag();
    }
  };

  const onTouchEnd = (e) => {
    e.preventDefault();
    // onDrag(e);

    setIsDragging(false);
    finishDrag();
  };

  const img0Pos = isPortraitMode ? [530, 900] : [540, 438];
  const img1Pos = isPortraitMode ? [366, 1000] : [770, 526];

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        overscrollBehavior: "none",
        overscrollBehaviorX: "none",
        margin: 0,
        visibility: hasLoaded ? "visible" : "none",
      }}
      // onWheel={onScroll}
    >
      <canvas
        ref={overlayCanvasRef}
        style={{
          position: "fixed",
          userSelect: "none",
          pointerEvents: "none",
          zIndex: 1000,
        }}
      ></canvas>
      <div
        draggable={true}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseUp={onDragEnd}
        style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}
      ></div>

      <div
        onDragOver={onDragOverEvent}
        onDragEnter={onDragOverEvent}
        onDragExit={onDragOverEvent}
        ref={canvasRoot}
        style={{
          transformOrigin: "top left",
          userSelect: "auto",
          pointerEvents: "auto",
        }}
      >
        <CanvasContext.Provider value={{ transform: rootTransform.current, isZooming: isZooming }}>
          <div className={clsx("gridBackground")}></div>
          <Draggable noShadow pos={[window.innerWidth - 128, window.innerHeight - 32]}>
            by josh shadik
          </Draggable>

          {orderedShortcuts.map((element, index) => {
            return (
              <Shortcut
                key={element.id}
                pos={element.pos}
                thumbnail={element.thumbnail}
                onOpen={(pos) => {
                  let openItem = { ...allItems[element.id] };
                  if (pos) {
                    openItem.initialPos = pos;
                  }
                  openItem.id = ++nextItemID;

                  let newOpenedItems = [...openedItems, openItem];
                  setOpenedItems(newOpenedItems);
                }}
                onStartDrag={() => {
                  let newOrdered = [...orderedShortcuts];
                  newOrdered.splice(index, 1);
                  newOrdered.push(element);

                  setOrderedShortcuts(newOrdered);
                }}
              ></Shortcut>
            );
          })}

          {openedItems.map((element, index) => {
            return (
              <StickyNote
                key={element.id}
                pos={element.initialPos}
                title={element.title}
                onClose={() => {
                  let newOpenedItems = [...openedItems];
                  newOpenedItems.splice(index, 1);

                  setOpenedItems(newOpenedItems);
                }}
                onStartDrag={() => {
                  let newOpenedItems = [...openedItems];
                  newOpenedItems.splice(index, 1);
                  newOpenedItems.push(element);

                  setOpenedItems(newOpenedItems);
                }}
              >
                {element.contents}
              </StickyNote>
            );
          })}
        </CanvasContext.Provider>
      </div>
    </div>
  );
}
